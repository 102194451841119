body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-weight: 400 !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-ms-overflow-style: scrollbar;
	font-size: 1rem !important;
	scroll-padding-top: 9.5rem;
}

.panel {
margin-bottom: 0 !important;

}

div[data-rbd-droppable-id="board"] {
	/* height: calc(-57.59px + 100vh); */
	height: calc(-115.18px + 100vh);
	padding-left: 1rem;
	background-color: #0000007d;
  }

input[type='radio'] {
    accent-color: #00acac
}

input[type='checkbox'] {
    accent-color: #00acac;
}

#selectMenuModalSlider li.react-multi-carousel-item {
	display: flex;
    justify-content: center;
}

@media screen and (min-width: 438px) and (max-width: 519px) {
	#selectMenuModalSlider {
		width: 400px !important;
	}
}

/* @media screen and (min-width: 391px) and (max-width: 437px) {
	#selectMenuModalSlider {
		width: px !important;
	}
} */

/* @media screen and (min-width: 300px) and (max-width: 390px) {
	#selectMenuModalSlider {
		width: 300px !important;
	}
} */

.dots-loading {
	position:absolute;
	width: 40px;
	z-index: 8888;
	aspect-ratio: 4;
	--_g: no-repeat radial-gradient(circle closest-side,#00acac 90%,#0000);
	background:
	  var(--_g) 0%   50%,
	  var(--_g) 50%  50%,
	  var(--_g) 100% 50%;
	background-size: calc(100%/3) 100%;
	animation: d7 1s infinite linear;
  }
  @keyframes d7 {
	  33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}
	  50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}
	  66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%  }
  }

.customOverlay {
	background: rgba(0, 0, 0, 0.7);
}

.successModal {
	min-width: 35rem;
	border-radius: 20px;
}

.locationModal,.selectItemModal {
	border-radius: 6px;
	padding: 0 !important;
	overflow: visible !important;
}

.restaurantMenuModal {
	border-radius: 6px;
	padding: 0 !important;
}

.popupModal {
	border-radius: 6px;
	padding: 1rem !important;
}

.blockModal {
	border-radius: 6px;
	padding: 0rem !important;
}

.smallModal{
	border-radius: 6px;
	padding: 1rem !important;
	width: 36rem !important;
}

@media screen and (min-width: 620px) and (max-width: 9999px) {
	.locationModal,.selectItemModal,.restaurantMenuModal {
		min-width: 35rem;
		max-width: 35rem !important;
	}
}

@media screen and (min-width: 520px) and (max-width: 619px) {
	.locationModal,.selectItemModal,.restaurantMenuModal {
		min-width: 30rem;
		max-width: 30rem !important;
	}
}

@media screen and (min-width: 434px) and (max-width: 519px) {
	.locationModal,.selectItemModal {
		min-width: 25rem;
		max-width: 25rem !important;
	}
}

@media screen and (min-width: 400px) and (max-width: 433px) {
	.locationModal,.selectItemModal {
		min-width: 23rem;
		max-width: 23rem !important;
	}
}

@media screen and (min-width: 355px) and (max-width: 399px) {
	.locationModal,.selectItemModal {
		min-width: 20rem;
		max-width: 20rem !important;
	}
}
@media screen and (min-width: 0px) and (max-width: 354px) {
	.locationModal,.selectItemModal {
		min-width: 17.5rem;
		max-width: 17.5rem !important;
	}
}

.downLoadReportModal {
	min-width: 32rem;
	max-width: 32rem !important;
	border-radius: 6px;
	padding: 0 !important;
}
.filterModal {
	min-width: 28rem;
	max-width: 28rem !important;
	border-radius: 6px;
	padding: 0 !important;
}

.reviewRequest {
	min-width: 32rem;
	max-width: 32rem !important;
	border-radius: 6px;
	padding: 0 !important;
}

.addReviewModal {
	min-width: 27rem;
	max-width: 27rem !important;
	border-radius: 6px;
	padding: 0 !important;
}
.createNewApiModal {
	min-width: 30rem;
	max-width: 30rem !important;
	border-radius: 6px;
	padding: 0 !important;
}

.editModal {
	min-width: 28rem;
	max-width: 30rem !important;
	border-radius: 6px;
	padding: 0 !important;
}

.addNewModal {
	min-width: 28rem;
	max-width: 28rem !important;
	border-radius: 6px;
	padding: 0 !important;
}

.bulkCardModal {
	border-radius: 6px;
	padding: 0 !important;
}

.compaignModal {
	max-width: 1200px !important;
}

p {
	margin-bottom: 0;
	margin: 0 0 0px;
}

*,
*::before,
*::after {
	-webkit-box-sizing: inherit;
	box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	/* font-family: 'Playfair Display', serif; */
}
div,span{
	/* font-family: 'Playfair Display', serif; */
}

#carousel div div.carousel .thumbs-wrapper {
	margin: 0rem !important;
}

#carousel div.carousel-root div.carousel.carousel-slider .control-dots {
	margin-bottom: 4.5rem;
}

@media screen and (min-width: 0px) and (max-width: 767px) {
	#carousel div.carousel-root div.carousel.carousel-slider .control-dots {
		margin-bottom: 1rem;
	}
}

#pre,
#next {
	display: none;
}

#testimonials div.carousel-root div.carousel.carousel-slider .control-dots {
	display: none;
}

#testimonialsPre,
#testimonialsNext {
	display: none;
}

.phoneInputFields {
	font-family: 'Roboto';
	width: 100% !important;
	line-height: 2.438rem !important;
	outline: none;
	font-weight: 400;
	text-align: left;
	font-size: 0.875rem !important;
	border-radius: 0.2rem !important;
	padding: 0.5rem 0.8rem !important;
	padding-left: 60px !important;
	border: 1px solid #d9d9d9 !important;
	color: #555;
	background: #ffffff;
	height: auto !important;
	border-left: 4px solid #00acac !important;
}

#phoneInput_dark .phoneInputFields {
    font-family: 'Roboto';
    width: 100% !important;
    line-height: 2rem !important;
    outline: none;
    font-weight: 400;
    text-align: left;
    font-size: 0.875rem !important;
    border-radius: 0.375rem !important;
    padding: 0.5rem 0.8rem !important;
    padding-left: 60px !important;
    border: 1px solid #434343 !important;;
    color: #dcdcdc !important;
    background: #141414 !important;
    height: auto !important;
}

.react-tel-input .flag-dropdown {
	top: 0.063rem !important;
	bottom: 0.063rem !important;
	left: 0.063rem !important;
	width: 3rem !important;
	background: #ffffff00 !important;
	border-bottom-left-radius: 0.375rem !important;
	border-top-left-radius: 0.375rem !important;
	border-top: none !important;
	border-bottom: none !important;
	border-left: none !important;
	border-right: 1px solid #d9d9d9 !important;
	padding-left: 0.2rem !important;
}

#phoneInput_dark > .react-tel-input .flag-dropdown {
    top: 0.063rem !important;
    bottom: 0.063rem !important;
    left: 0.063rem !important;
    width: 3rem !important;
    background: #ffffff00 !important;
    border-bottom-left-radius: 0.375rem !important;
    border-top-left-radius: 0.375rem !important;
    border-top: none !important;
    border-bottom: none !important;
    border-left: none !important;
    border-right: 1px solid #d9d9d9 !important;;
    padding-left: 0.2rem !important;
}

#phoneInput_dark .react-tel-input .country-list {
    background-color: #140d0d !important;
    color: white !important;
}

#phoneInput_dark .react-tel-input .country-list .country:hover {
    background-color: #434343 !important;
}

#phoneInput_dark .react-tel-input .country-list .country.highlight {
    background-color: #434343 !important;
}

/* .phoneInputFields:focus {
	border: 1px solid #E00000 !important;
	box-shadow: none !important;
}
.phoneInputFields:focus + .flag-dropdown {
	border-right: 1px solid #E00000 !important;
	box-shadow: none !important;
} */

.react-tel-input .form-control,
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag {
	background: #ffffff00 !important;
}

/* ......... Data Table Css ......... */
table {
	/* border-collapse: separate !important;
	border-spacing: 0 5px !important; */
	border-radius: 5px; /* You can adjust the pixel value based on your preference */
    overflow: visible; /* This ensures that the border-radius is applied to the table */
    border-collapse: none; /* This is important for styling individual cells */
}

.mdb-dataTable-head tr th {
	font-family: 'Roboto';
	font-weight: 500 !important;
	font-size: 13px !important;
	line-height: 5px;
	text-transform: capitalize;
	color: #333333;
	padding-bottom: 1.2rem !important;
	line-height: 1;
	background-color: #f2f4f5 !important;
}

.mdb-dataTable tbody tr td {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400 !important;
	font-size: 13px;
	line-height: 32px;
	text-transform: capitalize;
	color: #333333;
}
tr th:last-child {
	text-align: end;
}
.table-striped tbody tr:nth-of-type(odd) {
	background-color: #ffffff8c !important;
	border-radius: 4px;
}

.table-striped tbody tr:nth-of-type(even) {
	background-color: white !important;
	border-radius: 4px;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
	color: #666666 !important;
}

.mdb-datatable table tr {
	border-bottom: 0 !important;
}

.mdb-datatable > div:first-child {
    margin: 0rem !important;
}

div[data-test="datatable-search"] {
    padding: 0rem !important;
}

tr td:first-child {
	border-top-left-radius: 4px !important;
	border-bottom-left-radius: 4px !important;
}
tr td:last-child {
	border-top-right-radius: 4px !important;
	border-bottom-right-radius: 4px !important;
	text-align: center;
}

.table > thead > tr > th {
	vertical-align: bottom;
	border: 1px solid #e0e1e0 !important;
	text-align: center;
}


.table > tbody > tr > td,
.table > tfoot > tr > td {
	/* border-top: none !important; */
	border: 1px solid #e0e1e0 !important;
	text-align: center;
	/* padding: 1rem 0.75rem 1.1rem 0.75rem !important; */
}


.mdb-datatable-filter input{
	width: 100% !important;
	padding: 20px;
	border-radius: 5px;
}


div.mdb-datatable div.mdb-datatable-filter{
	width: 100% !important;
}


a:hover,
a:focus {
	text-decoration: none !important;
}

.form-control {
	box-shadow: none !important;
	/* padding: 0.5rem 0.8rem !important; */
}
/* ---------- ImageCarousel  ------- */
.image-gallery-thumbnails-container {
	text-align: start !important;
	padding: 0.15rem 0.5rem !important;
}

.image-gallery-thumbnails-container button {
	border: none !important;
	margin-right: 0.43rem;
}

.image-gallery-thumbnails-container button > span > img {
	border-radius: 4px !important;
	opacity: 0.5;
	object-fit: cover;
}

.image-gallery-thumbnails-container button > span > img {
	border-radius: 4px !important;
	opacity: 0.5;
	height: 5.7rem;
}
.image-gallery-thumbnails-container button.image-gallery-thumbnail.active > span > img {
	border-radius: 4px !important;
	opacity: 1 !important;
}

.image-gallery-image {
	object-fit: cover !important;
}

@media screen and (min-width: 992px) and (max-width: 9999px) {
	.image-gallery-image {
		height: 24.5rem;
	}
}
@media screen and (min-width: 0px) and (max-width: 992px) {
	.image-gallery-image {
		max-height: fit-content !important;
	}
	.image-gallery-thumbnails-container button > span > img {
		height: 4.7rem;
	}
}

/* ---------- ImageCarousel End  ------- */

/* ----------  AdminGraph ----------  */
.recharts-text {
	font-family: 'Roboto';
	font-weight: 400;
	font-size: 13px;
	line-height: 24px;
	padding: 0.5rem;
}
/* ----------  AdminGraph End ----------  */

/* --------SummerNote Fixing---------- */
.btn-default,
.btn-default.dropdown-toggle {
	color: #333 !important;
	background-color: #fff !important;
	border-color: #ccc !important;
}
.btn-sm,
.btn-group-sm > .btn {
	padding: 5px 10px !important;
	font-size: 12px !important;
	line-height: 1.5 !important;
	border-radius: 3px !important;
}
.btn {
	margin: 0 !important;
	text-transform: capitalize !important;
}
.btn:hover,
.btn:focus,
.btn:active {
	outline: 0 !important;
	box-shadow: none !important;
}
.btn-group,
.btn-group-vertical {
	box-shadow: none !important;
}
.btn-group > .btn:first-child {
	margin-left: 0 !important;
}
.btn-default.dropdown-toggle:hover,
.btn-default.dropdown-toggle:focus {
	color: #333 !important;
	background-color: #e6e6e6 !important;
	border-color: #adadad !important;
}
.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}
.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
	border-radius: 0 !important;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
	margin-left: -1px !important;
}
.dropdown-toggle::after {
	display: none !important;
}
.note-popover .popover-content .note-color .dropdown-toggle,
.note-editor .note-toolbar .note-color .dropdown-toggle {
	padding-left: 5px !important;
}
.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
	color: #333 !important;
	background-color: #d4d4d4 !important;
	border-color: #8c8c8c !important;
}
.btn-default:not([disabled]):not(.disabled):active,
.btn-default:not([disabled]):not(.disabled).active,
.show > .btn-default.dropdown-toggle {
	background-color: #d4d4d4 !important;
	box-shadow: none;
}
.btn:active,
.btn.active {
	background-image: none;
	outline: 0;
	box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%) !important;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
	color: #333 !important;
	background-color: #e6e6e6 !important;
	background-image: none;
	border-color: #adadad !important;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
	margin-left: -1px !important;
}
.btn-default:hover {
	color: #333 !important;
	background-color: #e6e6e6 !important;
	border-color: #adadad !important;
}
/* --------SummerNote Fixing End---------- */

/* --------SummerNote Model Fixing---------- */
button.close {
	padding: 0 !important;
	cursor: pointer;
	background: transparent;
	border: 0;
	appearance: none;
}

.modal-header {
	display: block !important;
}
.modal-header .close {
	margin: auto !important;
}
.note-group-select-from-files input {
	font: -webkit-control !important;
}

.btn-primary {
	color: #fff;
	background-color: #337ab7 !important;
	border-color: #2e6da4 !important;
}
.btn[disabled] {
	cursor: not-allowed !important;
}
.note-editable {
	background-color: transparent !important;
}
/* --------SummerNote Model Fixing End---------- */

/* --------Input Tags---------- */

.rti--container {
	background: transparent !important;
	border: 1px solid #e1e1e1 !important;
	border-radius: 0.375rem !important;
	line-height: 2rem !important;
	padding: 0.5rem 0.8rem !important;
	font-size: 0.875rem !important;
}
.rti--container > input {
	padding: 0 !important;
}

.rti--container:focus-within {
	border-color: #1fc3e0 !important;
	box-shadow: none !important;
}
.rti--tag {
	background: #56ccf2 !important;
	border-radius: 6px !important;
	padding: 0 0.2rem 0 0.7rem !important;
	color: #ffffff !important;
	font-family: 'Roboto' !important;
	font-weight: 400 !important;
	font-size: 14px !important;
	justify-content: space-between !important;
	text-transform: capitalize !important;
}

.rti--tag > span {
	padding-right: 0.5rem !important;
}

.rti--tag > button {
	font-size: 0.7rem !important;
	font-weight: 700 !important;
}
/* --------Input Tags End---------- */
.swal2-icon.swal2-success .swal2-success-ring{
    border: 0.25em solid #ffffff !important;
}

.swal2-icon.swal2-success [class^=swal2-success-line],.swal2-icon.swal2-success [class^=swal2-success-line]{
	background-color: #ffffff !important;
}

.swal2-popup{
	background: #fe5b60 !important;
	color: #ffffff !important;
}

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left],.swal2-icon.swal2-success .swal2-success-fix, .swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right{
	background-color: rgb(62 21 21 / 0%) !important;
}
.swal2-styled.swal2-confirm{
	background-color: #ffffff !important;
    color: #fe5b60 !important;
	padding: 0.5rem 2rem !important;
	border:none !important;
	outline: none !important;
}
/* --------React Select------------ */
.css-13cymwt-control{
	min-height: 3.1rem !important;
	box-shadow: 0.5px !important;
}

.css-t3ipsp-control{
	min-height: 3.1rem !important;
	box-shadow: 0.5px !important;
}


#customCarosal .carousel .carousel-status{
	background-color: #fff;
	padding: 0.3rem 1rem;
	color: gray;
	text-shadow: none;
	border-radius: 1rem;
	bottom: 1rem;
	right: 1rem;
	top: auto;
	font-size: 0.75rem;
}

/* REACT DATEPICKER/ */

.react-calendar__navigation__label {
	color: #40730a !important;
	/* font-weight: 700 !important; */
	/* font-size: 1.5rem; */
}

.react-calendar__month-view__weekdays__weekday {
	text-decoration: underline !important;
}
.react-calendar__tile--active {
	background-color: #03adac !important;
}
.react-calendar__navigation > button {
	color: #03adac !important
}

.react-calendar__tile:enabled:hover {
	background-color: #03adac !important;
	color: white;
    font-weight: 800 !important;
}
.react-calendar__navigation button:enabled:hover {
	background-color: #03adac !important;
	color: white;
    font-weight: 800 !important;
	color: white !important;
}

.react-calendar__tile--now {
	background-color: #03adac !important;
    color: white;
    font-weight: 800 !important;
}

.react-datetime-picker__wrapper {
	border: none !important;
}
.character {
	display: flex;
	justify-content: center !important;
	align-items: center !important;
	border: none;
	font-size: 20px;
	border-radius: 8px;

	/* light theme */
	color: #272729;
	background-color: #f6f5fa;
	box-shadow: 0 2px 0 #e4e2f5;
}
.upload {
	margin: 0;
}

.rmsc {
	--rmsc-main: #4285f4 !important;
	--rmsc-hover: #3f730a !important;
	--rmsc-selected: #e2e6ea;
	--rmsc-border: #ccc;
	--rmsc-gray: #aaa;
	--rmsc-bg: #fff;
	/* color: #fff; */
	--rmsc-p: 10px; /* Spacing */
	--rmsc-radius: 4px; /* Radius */
	--rmsc-h: 38px; /* Height */
}

ol,
ul {
	padding-left: 0rem;
}

.rmsc .select input:focus {
}

.dropdown-container {
	/* background: red !important; */
	border: 1px solid #efeff0 !important;
}
.select-item {
	margin: 0px !important;
	/* color: white !important; */
}

.select-item:hover {
	margin: 0px !important;
	color: white !important;
}

.paging-item {
	display: none !important;
}

/* //Spinner 1  */

.spinner-container {
	position: relative;
	width: 100px;
	height: 100px;
}

.spinner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	border: 5px solid #0fcba1;
	border-top-color: #fff;
	animation: spin 1s infinite linear;
}

@keyframes spin {
	0% {
		transform: rotateX(35deg) rotateY(45deg) rotateZ(0deg);
	}
	100% {
		transform: rotateX(35deg) rotateY(45deg) rotateZ(360deg);
	}
}

/* Chrome, Safari, Edge, Opera */
#quantity input::-webkit-outer-spin-button,
#quantity input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
#quantity input[type='number'] {
	-moz-appearance: textfield;
	/* text-align: center; */
}

.dropdown-content {
	z-index: 2 !important;
}

.react-input-emoji--container {
	background-color: #ffffff !important;
	border: 1px solid #ffffff !important;
	border-radius: 30px !important;
}
.react-input-emoji--input{
	height: 50px !important;
	padding: 13px 165px 13px 20px !important;
}

.react-input-emoji--button svg {
	fill: #6C757D !important;
}
.react-input-emoji--button__show svg {
	fill: #32cd32 !important;
}

.react-input-emoji--button{
	position: absolute !important;
	right:5.2rem;
	z-index:2;
}

@media screen and (min-width: 0px) and (max-width: 574px) {
	.react-input-emoji--input{
		padding: 13px 20px 13px 20px !important;
	}
	.react-input-emoji--button{
		bottom: -2.2rem;
	}
}

.react-emoji-picker--wrapper {
	z-index: 1 !important;
}

.react-input-emoji--container {
	margin: 0 !important;
}


#customCalendar .react-datetime-picker__inputGroup {
	display: none !important;
}

.react-datetime-picker__calendar{
	z-index: 2 !important;
}

.react-datetime-picker__button{
	padding:0rem !important;
}

.react-datetime-picker__inputGroup__input{
	padding: 0px 1px !important;
	height: auto !important;
}

.hide-month-icon .react-date-picker__button__icon {
	display: none;
  }

.react-datetime-picker__inputGroup__input--hasLeadingZero{
	margin-left: -0.014em !important;

}


.react-datetime-picker {
	display: inline-flex;
	position: relative;
  }

  .react-datetime-picker,
  .react-datetime-picker *,
  .react-datetime-picker *:before,
  .react-datetime-picker *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
  }

  .react-datetime-picker--disabled {
	background-color: #f0f0f0;
	color: #6d6d6d;
  }

  .react-datetime-picker__wrapper {
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;
	border: thin solid gray;
  }

  .react-datetime-picker__inputGroup {
	min-width: calc(4px + (4px * 3) + 0.54em * 6 + 0.217em * 2);
	flex-grow: 1;
	padding: 0 2px;
  }

  .react-datetime-picker__inputGroup__divider {
	padding: 1px 0;
	white-space: pre;
  }

  .react-datetime-picker__inputGroup__divider,
  .react-datetime-picker__inputGroup__leadingZero {
	display: inline-block;
  }

  .react-datetime-picker__inputGroup__input {
	min-width: 0.54em;
	height: calc(100% - 2px);
	position: relative;
	padding: 1px;
	border: 0;
	background: none;
	font: inherit;
	box-sizing: content-box;
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	appearance: textfield;
  }

  .react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
  }

  .react-datetime-picker__inputGroup__input:invalid {
	background: rgba(255, 0, 0, 0.1);
  }

  .react-datetime-picker__inputGroup__input--hasLeadingZero {
	margin-left: -0.54em;
	padding-left: calc(1px + 0.54em);
  }

  .react-datetime-picker__inputGroup__amPm {
	font: inherit;
	-webkit-appearance: menulist;
	-moz-appearance: menulist;
	appearance: menulist;
  }

  .react-datetime-picker__button {
	border: 0;
	background: transparent;
	padding: 4px 6px;
  }

  .react-datetime-picker__button:enabled {
	cursor: pointer;
  }

  .react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon,
  .react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
	stroke: #0078d7;
  }

  .react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
	stroke: #6d6d6d;
  }

  .react-datetime-picker__button svg {
	display: inherit;
  }

  .react-datetime-picker__calendar,
  .react-datetime-picker__clock {
	z-index: 1;
  }

  .react-datetime-picker__calendar--closed,
  .react-datetime-picker__clock--closed {
	display: none;
  }

  .react-datetime-picker__calendar {
	width: 350px;
	max-width: 100vw;
  }

  .react-datetime-picker__calendar .react-calendar {
	border-width: thin;
  }

  .react-datetime-picker__clock {
	width: 200px;
	height: 200px;
	max-width: 100vw;
	padding: 25px;
	background-color: white;
	border: thin solid #a0a096;
  }
  /* ..................... */
  /* .signUpSelete > div:first-of-type:hover {
    border-color: #fe5b60 !important;
} */
  /* .darkForSelect .signUpSelete > div:first-of-type {
    background-color: hsl(0deg 0% 7.84%) !important;
    border-color: hsl(0deg 0% 26.27%) !important;
	color: white !important;
} */


.react-calendar-timeline .rct-header-root{
	background: #00acac !important;
}

.Weekend{
	width: auto !important;
}

.timeline-calendar{
	position: relative;
	z-index: 0;
}

.loader-style{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(2px); /* Adjust the blur amount for the loader overlay */
    display: flex;
    align-items: center;
    justify-content: center;
	z-index: 1;
}
.react-responsive-modal-root {
z-index: 10000000;
}

.note-editor.note-frame .note-editing-area .note-editable, .note-editor.note-airframe .note-editing-area .note-editable{
	padding: 1rem !important;
}
.modal-backdrop{
	z-index: 0 !important;
}